@tailwind base;
@tailwind components;
@tailwind utilities;

.c-flex {
    @apply flex justify-center items-center;
}

.s-flex {
    @apply flex justify-start items-center;
}

.e-flex {
    @apply flex justify-end items-center;
}

.b-flex {
    @apply flex justify-between items-center;
}

.full {
    @apply w-full max-md:w-main;
}

.container {
    @apply mx-auto px-4  ;
    min-width: 1480px !important;
}
.containernone {
  @apply mx-auto   !max-w-main;
}
.my-container {
    @apply mx-auto   !max-w-main;
}
.marginTop {
    @apply mt-[30px];
}


/*美化所有滚动条*/
::-webkit-scrollbar {
    @apply w-[10px];
}

::-webkit-scrollbar-thumb {
    @apply rounded-full bg-[#dbdbdb]
}


#head-menu-cate::-webkit-scrollbar {
    @apply !w-[3px];
}

/*产品页面antd的select组件*/
.ant-select-selector{
    @apply !border-none
}
    
.forumEditor .tox .tox-tbtn svg {
    fill: #ff5f00 !important;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"]:focus {
    outline: none;
}


.order .ant-select{
  border: 1px solid #e6e6e6 !important;

}

.order .ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.order .ant-picker{
  border: 1px solid #e6e6e6 !important;
  border-radius:0 !important;

}
:where(.css-dev-only-do-not-override-8g996w).ant-picker {
  border-radius:0 !important;

}
.order .ant-input{
  border: 1px solid #e6e6e6 !important;
  border-radius:0 !important;

}

.order .ant-input-affix-wrapper {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}
.ant-input-search :where(.css-dev-only-do-not-override-8g996w).ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}

:where(.css-dev-only-do-not-override-8g996w).ant-btn {

  border-radius: 0px !important;
}

.exchange .ant-select{
  border: 1px solid #d9d9d9 !important;
}

.exchange .ant-input{
  border-radius: 0 !important;
}

/* 清除antd 表格默认下边距 */
.table-edit-form .ant-table-content  .ant-table-cell .ant-form-item {
  margin-bottom: 0;
}

.ebooks .textshadow{
  text-shadow: 0 0 10px #092C47 ;
  color: #ffffff;
}