@font-face {
  font-family: "iconfont"; /* Project id 3713810 */
  src: url('iconfont.woff2?t=1731900107883') format('woff2'),
       url('iconfont.woff?t=1731900107883') format('woff'),
       url('iconfont.ttf?t=1731900107883') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shuziqizhi:before {
  content: "\e6b6";
}

.icon-qizhi:before {
  content: "\e63c";
}

.icon-a-icon_specialplane:before {
  content: "\e62b";
}

.icon-pdf:before {
  content: "\e64f";
}

.icon-doc:before {
  content: "\e623";
}

.icon-logo-weibo:before {
  content: "\e618";
}

.icon-weixin-copy:before {
  content: "\e652";
}

.icon-QQ:before {
  content: "\e882";
}

.icon-QQ-circle-fill:before {
  content: "\e887";
}

.icon-weibo:before {
  content: "\e62f";
}

.icon-shop2:before {
  content: "\e693";
}

.icon-shop1:before {
  content: "\e609";
}

.icon-shop:before {
  content: "\e676";
}

.icon-shouye:before {
  content: "\e60d";
}

.icon-shopping:before {
  content: "\e67a";
}

.icon-headset:before {
  content: "\e601";
}

.icon-Persons:before {
  content: "\edf8";
}

.icon-huo:before {
  content: "\e66c";
}

.icon-cuowu:before {
  content: "\e62d";
}

.icon-weixin:before {
  content: "\e600";
}

.icon-khgx:before {
  content: "\e62c";
}

